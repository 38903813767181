import React from "react";
import Login from "../components/Login";

function Loginn() {
  return (
    <div>
      <div>
        <Login />
      </div>
    </div>
  );
};

export default Loginn;